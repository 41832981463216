import * as React from "react";
import { Modal } from "react-bootstrap";
import RHFFileUpload from "./RHFFileUpload";

interface IProp {
  show: boolean;
  onHide: () => void;
}
const SVGBodySetup: React.FC<IProp> = ({ show, onHide }): JSX.Element => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      className="label-edit-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Rec Tee
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>

        <RHFFileUpload fieldName="body" label="Upload .SVG" InputProps={{ accept: '.svg' }} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-danger">Save</button>
        <button className="btn btn-outline-danger">Cancel</button>
      </Modal.Footer>
    </Modal>
  );
};

export default SVGBodySetup;
