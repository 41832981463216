import * as React from "react";
import { Stack } from "react-bootstrap";
import UpdateOrderItem from "./UpdateOrderItem";
import Editable from "./Editable";
import RHFEditable from "./RHFEditable";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useAppSelector } from "store/hooks";
import { getPOStats, getShippingAddresses } from "store/OrderUpdateData.slice";

const PurchaseOrderSummary = React.memo(({ purchaseOrderId }) => {
  const allPOStats = useAppSelector(getPOStats)
  const stats = allPOStats[purchaseOrderId]
  return (
    <div
      style={{
        marginTop: "20px",
        paddingBlock: "10px",
        paddingInline: "20px",
      }}
    >
      <h5>Summary:</h5>
      <hr />
      <p>
        <b>Sub Total:</b> ${stats?.amountSubTotal}
      </p>
      <p>
        <b>Discount:</b> ${stats?.amountDiscount}
      </p>
      <p>
        <b>Shipping:</b> ${stats?.amountShipping}
      </p>
      <p>
        <b>Total:</b> ${stats?.amountTotal}
      </p>
    </div>
  );
});

const UpdatePurchaseOrder = ({ po }) => {
  const shippingAddresses = useAppSelector(getShippingAddresses)
  const { control } = useFormContext();

  return (
    <Stack>
      <div style={{ marginInline: "20px", marginBlock: "10px" }}>
        <div className="d-flex justify-content-between align-items-center">
          <h5>Items:</h5>
          <span>
            PO: <RHFEditable fieldName={`purchaseOrders.po_${po.id}.PO`} />
          </span>
        </div>
        <hr />
        <label htmlFor="shipAddr">Ship To:</label>
        <div className="d-flex mt-1">
          <Controller
            control={control}
            name={`purchaseOrders.po_${po.id}.addressId`}
            render={({ field: { value, onChange, ref } }) => (
              <select
                id="shipAddr"
                className="form-select"
                onChange={onChange}
                value={value}
              >
                {shippingAddresses?.map((el) => (
                  <option value={el.id} key={el.id}>{el.name}</option>
                ))}
              </select>
            )}
          />
        </div>
        <hr />
        <table
          style={{
            width: "100%",
            borderCollapse: "separate",
            borderSpacing: "0 8px",
          }}
        >
          <tbody>
            <tr>
              <th style={{ width: "35%", paddingLeft: "25px" }}>Item</th>
              <th style={{ width: "35%" }}>Quantities</th>
              <th style={{ width: "15%", textAlign: "center" }}>Total Qty</th>
              <th style={{ width: "15%", textAlign: "center" }}>Total Price</th>
              <th style={{ width: "15%", textAlign: "center" }}>Shipping</th>
            </tr>
            {po.orderItems?.map((item, i) => (
              <UpdateOrderItem
                key={item.id}
                item={item}
                itemIndex={i}
              />
            ))}
          </tbody>
        </table>
      </div>
      <PurchaseOrderSummary purchaseOrderId={po.id} />
    </Stack>
  );
};

export default UpdatePurchaseOrder;
