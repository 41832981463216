import * as React from "react";
import { toast } from "react-toastify";
import { useStore } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { Accordion, Card, Modal } from "react-bootstrap";

import Calculation from "./Calculation";
import AccordianHeader from "./AccordianHeader";
import UpdateOrderSummary from "./UpdateOrderSummary";
import UpdatePurchaseOrder from "./UpdatePurchaseOrder";
import UpdateItemPriceModal from "./UpdateItemPriceModal";
import UpdateItemShippingCostModal from "./UpdateItemShippingCostModal";

import * as orderService from "api/orderService";
import { fCurrency } from "utils/helperFunctions";
import { generatePricing } from "./UpdateOrder.utils";
import { isEmptyObj } from "utils/helperFunctions";
import * as helper from "./helper";

interface UpdateFormProps {
  orderData: any;
  onHide: () => any;
  setRefetch: (bool: boolean) => void;
}
interface FormValues {
  quantities: {
    [key: string]: {
      XS: number;
      S: number;
      M: number;
      L: number;
      XL: number;
      XXL: number;
    };
  };
  purchaseOrders: { [key: string]: { PO: string; addressId: number } };
}
interface OrderItem {
  id: number;
  templateId: number;
  skuQtyXS: number;
  skuQtyS: number;
  skuQtyM: number;
  skuQtyL: number;
  skuQtyXL: number;
  skuQtyXXL: number;
  quantity: number;
  oldPrice: number;
  price: number;
}
interface PurchaseOrder {
  id: number;
  PO: string;
  addressId: number;
  orderItems: OrderItem[];
  amountSubTotal: number;
  amountDiscount: number;
  amountShipping: number;
  amountTotal: number;
}
interface Stats { amountSubTotal: number, amountDiscount: number, amountShipping: number, amountTotal: number  }

const SIZES = ['XS', 'S', 'M', 'L', 'XL', 'XXL'] as ['XS', 'S', 'M', 'L', 'XL', 'XXL']

const resolver = async (values: FormValues) => {
  const valuesObj = { ...values };
  const errorsObj = {} as { [key: string]: { type: string; message: string } };
  try {
    for (let key in values.quantities) {
      let sum = 0;
      for (let size of SIZES) {
        let sizeValue = Number(values.quantities[key][size]);
        sizeValue = isNaN(sizeValue) ? 0 : sizeValue;
        valuesObj.quantities[key][size] = sizeValue;
        sum += sizeValue;
      }
      if (sum <= 0)
        SIZES.forEach((size) => {
          errorsObj[`quantities.${key}.${size}`] = {
            type: "min",
            message: "Must be a greater than zero",
          };
        });
    }
    for (let key in values.purchaseOrders) {
      if (!values.purchaseOrders?.[key].addressId) {
        errorsObj[`purchaseOrders.${key}.addressId`] = {
          type: "required",
          message: "Shipping address field is required",
        };
      }
      if (!values.purchaseOrders?.[key].PO?.trim()) {
        errorsObj[`purchaseOrders.${key}.PO`] = {
          type: "required",
          message: "PO number is required",
        };
      }
    }
    if (!isEmptyObj(errorsObj)) {
      throw new Error("Validation Error");
    }
    // console.log('Valid Values:', valuesObj);
    return {
      values: valuesObj,
      errors: {},
    };
  } catch (err) {
    // console.error('Validation Errors:', err);
    return {
      values: {},
      errors: errorsObj,
    };
  }
};

const UpdateForm: React.FC<UpdateFormProps> = ({
  orderData,
  onHide,
  setRefetch,
}): JSX.Element => {
  const store = useStore();
  const methods = useForm<FormValues>({
    resolver: resolver,
    defaultValues: helper.transformAsFormValues(orderData),
    shouldUnregister: true,
  });
  const isSubmitting = methods.formState.isSubmitting;

  const [priceModal, setPriceModal] = React.useState(false);
  const [shippingModal, setShippingModal] = React.useState(false);

  const togglePriceModal = () => setPriceModal((prev) => !prev);
  const toggleShippingModal = () => setShippingModal((prev) => !prev);

  const handleUpdate = async (values: FormValues) => {
    try {
      const storeValues = store.getState() as unknown as any;
      const orderData = storeValues.orderUpdateData.orderData;
      const itemInfo = storeValues.orderUpdateData.itemInfo;
      const shippingAddresses = storeValues.orderUpdateData.shippingAddresses;
      const shippingRates = storeValues.orderUpdateData.shippingRates;
      const templatePriceSlabs = storeValues.orderUpdateData.templatePriceSlabs;
      const purchaseOrdersMap = values.purchaseOrders;
      const quantitiesMap = values.quantities as any;

      const templatesQty = {} as { [key: number]: number };
      const itemQuantities = {} as { [key: string]: number };
      const templatePricing = {} as any;
      const templateGenerator = {} as any;

      for (let id in itemInfo) {
        itemQuantities[id] =
          (itemQuantities[id] ?? 0) +
          SIZES.reduce((a: number, c: string) => {
            const val = Number(quantitiesMap[`item_${id}`][c]);
            a += typeof val === "number" ? val : 0;
            return a;
          }, 0);
      }

      for (let id in itemInfo) {
        const key = itemInfo[id].templateId;
        if (typeof templatesQty[key] === "undefined") templatesQty[key] = 0;
        templatesQty[key] += itemQuantities[id];
      }

      for (let templateId in templatePriceSlabs) {
        templateGenerator[templateId] = generatePricing({
          dozenWisePrice: templatePriceSlabs[templateId],
        });
      }

      for (let templateId in templatesQty) {
        const qty = templatesQty[templateId];
        templatePricing[templateId] = templateGenerator[templateId].forQty(qty);
      }
      const poStats = {} as { [key: number]: Stats };
      const itemStats = {} as {
        [key: number]: {
          quantity: number;
          oldPrice: number;
          price: number;
          shipping: number;
        };
      };
      const orderStats = {
        amountSubTotal: 0,
        amountDiscount: 0,
        amountShipping: 0,
        amountTotal: 0,
      } as Stats;
      orderData?.purchaseOrders.forEach((po: any) => {
        if (typeof poStats[po.id] === "undefined") {
          poStats[po.id] = {
            amountSubTotal: 0,
            amountDiscount: 0,
            amountShipping: 0,
            amountTotal: 0,
          };
        }
        po.orderItems.forEach((item: any) => {
          if (!templatePricing[item.templateId])
            throw new Error("Something went wrong");

          const shippingRate =
            shippingRates?.[item.blankGarmentStyleNumber] ?? 0;
          const retailPrice = fCurrency(
            templatePricing[item.templateId].baseRate * itemQuantities[item.id]
          );
          const discountedPrice = fCurrency(
            templatePricing[item.templateId].rate * itemQuantities[item.id]
          );
          const shipping = fCurrency(shippingRate * itemQuantities[item.id]);
          const discount = fCurrency(retailPrice - discountedPrice);

          itemStats[item.id] = {
            quantity: itemQuantities[item.id],
            oldPrice: retailPrice,
            price: discountedPrice,
            shipping: shipping,
          };

          poStats[po.id].amountSubTotal = fCurrency(
            poStats[po.id].amountSubTotal + discountedPrice
          );
          poStats[po.id].amountDiscount = fCurrency(
            poStats[po.id].amountDiscount + discount
          );
          poStats[po.id].amountShipping = fCurrency(
            poStats[po.id].amountShipping + shipping
          );
          poStats[po.id].amountTotal = fCurrency(
            poStats[po.id].amountTotal + discountedPrice + shipping
          );
        });

        orderStats.amountSubTotal = fCurrency(
          orderStats.amountSubTotal + poStats[po.id].amountSubTotal
        );
        orderStats.amountDiscount = fCurrency(
          orderStats.amountDiscount + poStats[po.id].amountDiscount
        );
        orderStats.amountShipping = fCurrency(
          orderStats.amountShipping + poStats[po.id].amountShipping
        );
        orderStats.amountTotal = fCurrency(
          orderStats.amountTotal + poStats[po.id].amountTotal
        );
      });

      const payload = {
        purchaseOrders: [] as any[],
        amountSubTotal: orderStats.amountSubTotal,
        amountDiscount: orderStats.amountDiscount,
        amountShipping: orderStats.amountShipping,
        amountTotal: orderStats.amountTotal,
      };

      orderData.purchaseOrders.forEach((po: any) => {
        const poKey = `po_${po.id}`;
        const poObj = {} as any;
        const addressObj = shippingAddresses.find(
          (el: any) => el.id === Number(purchaseOrdersMap[poKey].addressId)
        );
        if (!addressObj) throw new Error("Address required");

        poObj.orderId = orderData.id;
        poObj.purchaseOrderId = po.id;

        poObj.addressId = purchaseOrdersMap[poKey].addressId;
        poObj.PO = purchaseOrdersMap[poKey].PO;
        poObj.shipTo = addressObj.shipTo;
        poObj.shipToName = addressObj.shipToName;
        poObj.shipToId = addressObj.shipToId;
        poObj.address1 = addressObj.address1;
        poObj.address2 = addressObj.address2 ?? "";
        poObj.city = addressObj.city;
        poObj.state = addressObj.state;
        poObj.country = addressObj.country;
        poObj.postalCode = addressObj.postalCode;
        poObj.orderItems = [] as any;

        po.orderItems.forEach((item: any) => {
          const quantities = quantitiesMap[`item_${item.id}`];
          const itemObj = {} as any;

          itemObj.itemId = item.id;
          itemObj.orderId = orderData.orderId;
          itemObj.purchaseOrderId = po.id;

          itemObj.skuConcat = item.skuConcat;
          itemObj.skuIdXS = item.skuIdXS;
          itemObj.skuIdS = item.skuIdS;
          itemObj.skuIdM = item.skuIdM;
          itemObj.skuIdL = item.skuIdL;
          itemObj.skuIdXL = item.skuIdXL;
          itemObj.skuIdXXL = item.skuIdXXL;

          itemObj.skuQtyXS = quantities.XS;
          itemObj.skuQtyS = quantities.S;
          itemObj.skuQtyM = quantities.M;
          itemObj.skuQtyL = quantities.L;
          itemObj.skuQtyXL = quantities.XL;
          itemObj.skuQtyXXL = quantities.XXL;

          itemObj.quantity = itemStats[item.id].quantity;
          itemObj.price = itemStats[item.id].price;
          itemObj.oldPrice = itemStats[item.id].oldPrice;
          poObj.orderItems.push(itemObj);
        });
        poObj.amountSubTotal = poStats[po.id].amountSubTotal;
        poObj.amountDiscount = poStats[po.id].amountDiscount;
        poObj.amountShipping = poStats[po.id].amountShipping;
        poObj.amountTotal = poStats[po.id].amountTotal;
        payload.purchaseOrders.push(poObj);
      });

      await orderService.updateOrder(orderData.id, payload);
      toast.success("Order updated successfully.");
      setRefetch(true);
      onHide();
    } catch (err) {
      toast.error("failed to update order.");
    }
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleUpdate)}>
        <Modal.Header>
          <h5 className="mb-0">Update Order {orderData.orderNumber}</h5>
        </Modal.Header>
        <Modal.Body>
          <Calculation />
          <Accordion defaultActiveKey="po_0">
            <Card className="mb-3">
              <Card.Header>Order Summary</Card.Header>
              <UpdateOrderSummary />
            </Card>
            {orderData?.purchaseOrders.map((po: any, i: number) => (
              <Card className="mb-1" key={po.id}>
                <Card.Header>
                  <AccordianHeader
                    eventKey={`po_${i}`}
                    PO={po.PO}
                    openPriceModal={togglePriceModal}
                    openShippingModal={toggleShippingModal}
                  />
                </Card.Header>
                <Accordion.Collapse eventKey={`po_${i}`}>
                  <UpdatePurchaseOrder po={po} />
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
          {priceModal && <UpdateItemPriceModal onHide={togglePriceModal} />}
          {shippingModal && (
            <UpdateItemShippingCostModal onHide={toggleShippingModal} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-outline-danger me-2"
              onClick={onHide}
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-danger"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Updating..." : "Update"}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </FormProvider>
  );
};

export default UpdateForm;
