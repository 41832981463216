import * as React from "react";

interface IInputProps {
  accept?: string;
  className?: string;
  placeholder?: string;
  multiple?: boolean;
}

interface IProp {
  fieldName: string;
  label: string;
  InputProps: IInputProps;
}

const RHFFileUpload: React.FC<IProp> = ({
  fieldName,
  label,
  InputProps,
}): JSX.Element => {

  return (
    <button className="btn btn-upload position-relative">
      <span>
        <i className="fa-solid fa-cloud-arrow-up heading1"></i>
        <span className="d-block">{label}</span>
        <input
          name={fieldName}
          type="file"
          className="hidden-input"
          {...InputProps}
          value={''}
          onChange={(e) => console.log(e.target.files?.[0])}
        />
      </span>
    </button>
  );
};

export default RHFFileUpload;
